import React from 'react';

const IconStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10 10 10 10"
    width="10"
    height="10"
    fill="currentColor">
    <path d="M4.582 0.658a0.833 0.833 0 0 1 0.835 0l3.125 1.809a0.833 0.833 0 0 1 0.416 0.721v3.623a0.833 0.833 0 0 1 -0.416 0.721l-3.125 1.81a0.833 0.833 0 0 1 -0.835 0l-3.125 -1.809a0.833 0.833 0 0 1 -0.416 -0.721V3.189a0.833 0.833 0 0 1 0.416 -0.721zM2.188 3.372a0.208 0.208 0 0 0 -0.313 0.18v2.779a0.833 0.833 0 0 0 0.416 0.721l2.396 1.388a0.208 0.208 0 0 0 0.313 -0.18v-2.779a0.833 0.833 0 0 0 -0.416 -0.721zm4.382 -1.073c-0.199 -0.115 -0.523 -0.115 -0.722 0s-0.199 0.302 0 0.417 0.523 0.115 0.722 0 0.199 -0.302 0 -0.417m-2.417 0c-0.199 -0.115 -0.523 -0.115 -0.722 0s-0.199 0.302 0 0.417 0.523 0.115 0.722 0c0.2 -0.115 0.2 -0.302 0 -0.417m2.927 4.303c0.249 -0.144 0.451 -0.493 0.451 -0.781 0 -0.287 -0.202 -0.404 -0.451 -0.26S6.629 6.054 6.629 6.342s0.202 0.404 0.451 0.26M2.652 5.083c0.199 0.115 0.361 0.022 0.361 -0.208 0 -0.23 -0.162 -0.51 -0.361 -0.625s-0.361 -0.022 -0.361 0.208c0 0.23 0.162 0.51 0.361 0.625m1.804 2.291c0 0.23 -0.162 0.323 -0.361 0.208s-0.361 -0.395 -0.361 -0.625 0.162 -0.323 0.361 -0.208 0.361 0.395 0.361 0.625M3.013 6.542c0 0.23 -0.162 0.324 -0.361 0.208 -0.199 -0.115 -0.361 -0.395 -0.361 -0.625 0 -0.23 0.162 -0.323 0.361 -0.208 0.199 0.115 0.361 0.395 0.361 0.625m1.443 -0.833c0 0.23 -0.162 0.324 -0.361 0.208 -0.2 -0.115 -0.361 -0.395 -0.361 -0.625 0 -0.23 0.161 -0.323 0.361 -0.208 0.199 0.115 0.361 0.395 0.361 0.625" />
  </svg>
);

export default IconStar;
